.modal {
  transition: all 0.25s;
  opacity: 0;
  visibility: hidden;
}

.modal.show {
  opacity: 1;
  visibility: visible;
}
