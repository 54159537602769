table.ui-table tbody tr + tr {
  border-top: 1px solid #e5e5e5;
}

table.ui-table thead {
  background-color: #ececec;
}

table.ui-table tbody tr:hover {
  background-color: #f5f5f5;
}
