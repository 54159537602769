@keyframes dropdown {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}

@keyframes undropdown {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
}

.ui-dropdown-wrapper {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  animation: undropdown 0.3s alternate;
}

.ui-dropdown-wrapper.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  animation: dropdown 0.3s alternate;
}
