.ui-switch {
  display: inline-block;
  cursor: pointer;
}

.ui-switch input {
  display: none;
}

.ui-switch input:checked + .ui-switch-bar {
  background-color: #404040;
}

.ui-switch input:checked + .ui-switch-bar::after {
  left: calc(100% - 22px);
  background-color: white;
}

.ui-switch .ui-switch-bar {
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #e8e8e8;
  transition: all 0.3s;
}

.ui-switch .ui-switch-bar::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #c2c2c2;
  transition: all 0.3s;
}
