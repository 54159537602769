@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Noto Sans KR", sans-serif;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  background-color: #fafafa;
  user-select: none;
}

ul,
li {
  list-style: none;
}

input::placeholder,
textarea::placeholder {
  color: #aeaeae;
}

input + .input-slider {
  background-color: #e5e5e5;
}

input + .input-slider:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: width 0.25s;
  background-color: #404040;
}

input:focus + .input-slider:after {
  width: 100%;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.shadow-custom {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
}

.overflow-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: #555;
}

*::-webkit-scrollbar-track {
  background-color: #ccc;
}
