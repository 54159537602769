.ui-checkbox input[type="checkbox"] {
  display: none;
}

.ui-checkbox input:checked + .ui-checkbox-box {
  background-color: #efefef;
  border: 1px solid #242424;
}

.ui-checkbox input:checked + .ui-checkbox-box svg {
  display: block;
  color: #242424;
}

.ui-checkbox input:checked + .ui-checkbox-box + .ui-checkbox-label {
  color: #242424;
}

.ui-checkbox .ui-checkbox-box {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #dbdbdb;
}

.ui-checkbox .ui-checkbox-box svg {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ui-checkbox .ui-checkbox-label {
}
