.values-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border-top: 2px solid #737373;
  border-bottom: 1px solid #737373;
  white-space: nowrap;
  table-layout: auto;
}

.values-table thead {
  border-bottom: 1px solid #d4d4d4;
  font-size: 13px;
  text-align: center;
  background-color: #f5f5f5;
  position: sticky;
  top: 1px;
  z-index: 10;
  user-select: none;
}

.values-table thead th {
  font-weight: 500;
  font-size: 13px;
  padding: 0 20px;
}

.values-table thead::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0px;
  background-color: #d4d4d4;
}

.values-table thead::before {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  top: -1px;
  left: 0px;
  background-color: #737373;
}

.values-table thead tr,
.values-table tbody tr {
  height: 36px;
}

.values-table thead tr + tr,
.values-table tbody tr + tr {
  border-top: 1px solid #d4d4d4;
}

.values-table thead th:first-child {
  border-right: 1px solid #d4d4d4;
}

.values-table thead th + th,
.values-table tbody td + td {
  border-left: 1px solid #d4d4d4;
}

.values-table tbody td {
  position: relative;
  padding: 4px 16px;
  font-size: 13px;
  word-break: break-all;
  white-space: nowrap;
}

.values-table tbody td.empty {
  padding: 48px 0;
  text-align: center;
}

.values-table tbody td:not(.empty):first-child {
  z-index: 9;
  position: sticky;
  left: 0;
  background-color: #f5f5f5;
}

.values-table thead tr:first-child th:first-child {
  z-index: 9;
  position: sticky;
  left: 0;
  background-color: #f5f5f5;
}

.values-table thead th:first-child:after,
.values-table tbody td:first-child:after {
  content: "";
  position: absolute;
  display: block;
  height: 100%;
  width: 1px;
  top: 0;
  right: -1px;
  z-index: 10;
  background-color: #d4d4d4;
}

.values-table tbody td:not(:first-child) {
  text-align: right;
}

.values-table tbody tr:hover {
  background-color: #f4f4f4;
}
